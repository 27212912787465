import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import axios from "axios";
import PortalVue from "portal-vue";
import VeeValidate from "vee-validate";
import validationMessages from "vee-validate/dist/locale/es";
import vSelect from "vue-select";
import i18n from "./i18n";
import "./directives";
import "./styles/main.scss"
//import "./styles/mdb.scss";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(PortalVue);
Vue.use(VeeValidate, {
  i18n,
  dictionary: {
   es: validationMessages
  },
  inject: true,
  fieldsBagName: "veeFields",
});
Vue.component("v-select", vSelect);

Vue.config.productionTip = false;

router.beforeEach(async (to, from, next) => {
  if (to.name === "login") {
    next();
  } else {
    try {
      let user = store.state.user;
      if (!user || !user.permission) {
        let getUser = await store.dispatch("getUser");
        let sessionUser = getUser.data.result;
        store.state.user = sessionUser;
        if (!sessionUser) {
          next("/login");
        }
      }

      if (
        !axios.defaults.headers.post["X-CSRF-Token"] ||
        axios.defaults.headers.post["X-CSRF-Token"] === ""
      ) {
        axios.defaults.withCredentials = true;
        try {
          let token = await axios.get(store.state.host + "api/v1/csrfToken");
          axios.defaults.headers.post["X-CSRF-Token"] = token.data._csrf;
          axios.defaults.headers.delete["X-CSRF-Token"] = token.data._csrf;
          axios.defaults.headers.put["X-CSRF-Token"] = token.data._csrf;
        } catch (err) {
          console.log(err, "error");
        }
      }
      next();
    } catch (err) {
      console.log("Usuario no autenticado");
      next("/login");
    }
  }
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount("#app")
