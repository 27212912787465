import i18n from "@/i18n"
import _ from "lodash"
import formConfigEN from "../../config/en/formConfig_en.json"
import main from "../index.js"

const mutations = {
  setValue(state, payload) {
    state[payload.key] += payload.value
  },
  updateField(state, payload) {
    state.formData[payload.key] = payload.value
  },
  formConfig(state) {
    const locale = i18n.locale
    //const selectedType = state.selectedType
    let formConfig = 0

    if (locale === "en") {
      state.formConfig = formConfigEN
      formConfig = _.find(formConfigEN.forms, ["key", 1]).fields
    } else if (locale === "es-MX") {
      state.formConfig = formConfigEN
      formConfig = _.find(formConfigEN.forms, ["key", 1]).fields
    }

    formConfig.forEach(field => {
      if (main.state.documentToEdit === "") {
        state.formData[field.name] = ""
      } else {
        switch(field.name) {
          case "type":
            state.formData[field.name] = {
              typeId: main.state.documentToEdit.type,
              typeText: main.state.documentToEdit.nametype,
              color: main.state.documentToEdit.color
            }
            break
          case "document": {
            let fileURL = `data:${main.state.documentToEdit.datatype};base64,${main.state.documentToEdit.document}`
            let arr = fileURL.split(','),
              mime = arr[0].match(/:(.*?);/)[1],
              bstr = atob(arr[1]), 
              n = bstr.length, 
              u8arr = new Uint8Array(n);
      
            while(n--){
              u8arr[n] = bstr.charCodeAt(n);
            }
      
            state.formData[field.name] = {
              file: new File([u8arr], main.state.documentToEdit.title, {type:mime})
            }
            break
          }
          case "state":
            state.formData[field.name] = main.state.documentToEdit.entity
            break
          default:
            state.formData[field.name] = main.state.documentToEdit[field.name];
            break
        } 
      }
    });
  }
}

const state = {
  formData: {},
  testimonies: [],
  selectedType: 0,
  testimonyTypes: [
    {
      key: 1,
      value: "I have lost someone to COVID-19",
      valueES: "Perdí un ser querido por COVID-19",
    },
    {
      key: 2,
      value: "I am a survivor of COVID-19",
      valueES: "Soy sobreviviente de COVID-19",
    },
    {
      key: 3,
      value: "I provide care for patients during the pandemic",
      valueES: "Cuido a pacientes durante la pandemia",
    },
    {
      key: 4,
      value: "I have lost income during the pandemic",
      valueES: "Perdí ingresos durante la pandemia",
    },
    {
      key: 5,
      value: "Other testimony about the pandemic",
      valueES: "Otro testimonio sobre la pandemia",
    },
  ],
}

export default {
  namespaced: true,
  mutations,
  state,
}
