<template lang="pug">
  #App
    router-view(:key="$route.fullPath")
</template>

<script>
export default {
  name: "App",
}
</script>

<style lang="scss"></style>
