import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
    children: [
      {
        path: "/form",
        name: "form",
        component: () => import("@/components/FormConfigProvider.vue"),
      },
      {
        path: "/documents",
        name: "documents",
        component: () => import("@/views/Documents.vue"),
      },
      {
        path: "/edit",
        name: "edit",
        component: () => import("@/views/Edit.vue")
      }
    ],
    meta: {
      requiresAuth: true
    },
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
