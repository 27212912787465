import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import lead from "./form/lead.js";

Vue.use(Vuex);

const requireComponent = require.context(
  `../../public/assets/jsons`,
  false,
  /.(json)$/
);

export default new Vuex.Store({
  state: {
    strict: process.env.NODE_ENV !== `production`,
    host: "https://plataformaciudadanadefosas.org/",
    logo: require("@/assets/img/dc.png"),
    chooser: true,
    selectedType: 0,
    user: {},
    documents: "",
    filters: "",
    documentToEdit: "",
    notification: {
      active: false,
      text: "",
      color: "",
    },
  },
  mutations: {
    setValue(state, payload) {
      state[payload.key] += payload.value
    },
    setNotification(state, payload) {
      state.notification = payload;
    }
  },
  actions: {
    login({ state }, user) {
      return axios.post(`${state.host}api/v1/login`, {
        email: user.email,
        password: user.password,
      });
    },
    logout({ state }) {
      return axios.get(`${state.host}api/v1/logout`);
    },
    getUser({ state }) {
      return axios.get(`${state.host}api/v1/getSessionUser`);
    },
    saveDocument({ state }, docInfo) {
      return axios.post(`${state.host}api/v1/saveDocument`, docInfo);
    },
    editDocument({ state }, docInfo) {
      return axios.post(`${state.host}api/v1/editDocument`, docInfo);
    },
    deleteDocument({ state }, docInfo) {
      return axios.delete(`${state.host}api/v1/deleteDocument`,
        {
          data: {
            id: docInfo
          }
        }
      );
    },
    getAllDocumentsInfo({ state }) {
      return axios.get(`${state.host}api/v1/getDocumentsInfo`);
    },
    getDocument({ state }, docInfo) {
      return axios.put(`${state.host}api/v1/getDocument`,
        {
          id: docInfo
        }
      );
    },
    filterDocByState({ state }, selection) {
      // Find selected filter on state object, change model and replace it for watch action
      let filterToChange = state.filters.find(f => f.id === selection.filterId);
      filterToChange.model =
        typeof selection.value === "string"
          ? selection.value
          : selection.value[selection.textParam];
      state.filters = state.filters.map(nf => {
        if (nf.id === selection.filterId) {
          nf = filterToChange;
        }
        return nf;
      });
    },
    clearDocStateFilter({ state }, selection) {
      // Find selected filter on state object, change model and replace it for watch action
      let filterToChange = state.filters.find(f => f.id === selection.filterId);
      filterToChange.model = selection.value;
      state.filters = state.filters.map(nf => {
        if (nf.id === selection.filterId) {
          nf = filterToChange;
        }
        return nf;
      });
    },
    async initAllForms({ state }) {
      return new Promise(resolve => {
        requireComponent.keys().forEach(async (fileName, i) => {
          let axiosFile = fileName.replace(/^.\//, "");
          let jsonResponse = await axios.get(
            `${window.location.origin}/assets/jsons/${axiosFile}`
          );
          let stateVariable = axiosFile.replace(/\.\w+$/, "");
          state[stateVariable] = jsonResponse.data;
          if (i === requireComponent.keys().length - 1) {
            return resolve("ok");
          }
        });
      });
    }
  },
  modules: { lead },
})
